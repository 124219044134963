import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import React from 'react'
import Layout from "components/layout"
import Meta from "components/meta"

const aboutus = () => (
  
    <Layout>
            <Meta
      title="CONTACT US"
      />
<>
<p>メールはこちら。</p>
<StaticImage
      src="../images/magurochan_gmail.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />

<p>または、TwitterのDMへどうぞ。</p>
<a class="twitter-dm-button" href="https://twitter.com/messages/compose?recipient_id=1187272497986596864&amp;ref_src=twsrc%5Etfw" data-screen-name="_magurochan" data-show-count="false">Message @</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </>
    </Layout>
)
export default aboutus